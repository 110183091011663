<template>
  <teleport v-if="mountedComponent" to="#header-nav">
    <CBreadcrumbItem active>
      {{ scope.name }}
    </CBreadcrumbItem>
  </teleport>
  <div v-if="dataLoaded" class="scope-upload" :aria-disabled="uploading">
    <div class="scope-upload__content">

      <div class="scope-upload__scope-info">
        <h1 class="scope-upload__scope-name">
          {{ scope.name }}
        </h1>
        <p class="scope-upload__scope-updated">
          {{ updatedSummaryString }}
        </p>
      </div>

      <div class="scope-upload__scope-summary">
        <span
          v-for="(val, key) in labels"
          :key="key"
          class="scope-upload__scope-summary-item">
          {{ translate(key) }}: <strong>{{ val }}</strong>
        </span>
      </div>


      <div class="scope-upload__table scope-upload__table_top">
        <h3 class="scope-upload__table-title">
          <img src="@/assets/images/crown.svg" alt="crown" />
          Primary Documents
        </h3>
        <p class="scope-upload__table-info">
          These documents are required before the flowdown process can start
        </p>
        <ScopeLoadingTable
          :key="itemsUpdated"
          :primary="true"
          :items="primary_tasks"
          :name-edition="nameEditing"
          @open-modal="onModalOpen"
          @file-loaded="onFileLoaded"
          @file-deleted="onFileDelete"
          @name-changed="onDocumentNameChange"
          @name-edit-started="nameEditing = true"
          @name-edit-finished="nameEditing = false" />
      </div>

      <div class="scope-upload__table">
        <h3 class="scope-upload__table-title">
          Documents
        </h3>
        <p class="scope-upload__table-info">
          These documents are required and are part of the flowdown process
        </p>
        <ScopeLoadingTable
          :key="itemsUpdated"
          :primary="false"
          :items="other_tasks"
          :name-edition="nameEditing"
          @open-modal="onModalOpen"
          @file-loaded="onFileLoaded"
          @file-deleted="onFileDelete"
          @name-changed="onDocumentNameChange"
          @name-edit-started="nameEditing = true"
          @name-edit-finished="nameEditing = false" />
      </div>

      <div class="scope-upload__footer">
        <CButton variant="outline" color="primary" @click="onCancel" :disabled="saveDisabled || filesNotLoaded">
          Cancel
        </CButton>
        <CButton color="primary" @click="onSave" :disabled="saveDisabled">
          {{ uploading ? 'Saving...' : 'Save uploaded document(s)' }}
          <CSpinner size="sm" v-if="uploading" />
        </CButton>

        <span class="scope-upload__footer-counter">
          <strong>{{ scope.completed_documents || 0 }}</strong> Document(s) Uploaded
        </span>
        <router-link :to="{ name: 'ProjectDocuments', params: {id: $route.params.id} }" class="scope-upload__footer-link">
          <img src="@/assets/images/upload.svg" alt="upload" />
          View Uploaded Documents
          <img
            class="scope-upload__footer-arrow"
            src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='303C54'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e" alt="chevron up" />
        </router-link>
      </div>

    </div>
  </div>

  <div v-else class="scope-upload__loading">
    <CSpinner />
  </div>

  <CModal class="overflow-modal" :visible="modals.noTasks" backdrop="static">
    <div class="app-modal scope-upload__modal">
      <div class="app-modal__icon">
        <CIcon class="scope-upload__modal-icon" name="cil-warning" />
      </div>
      <h4 class="app-modal__title">No new documents uploaded</h4>
      <p class="app-modal__text">
        Please upload a document
      </p>
      <div class="app-modal__buttons">
        <CButton color="primary" @click="modals.noTasks = false">Got it!</CButton>
      </div>
    </div>
  </CModal>

  <CModal class="overflow-modal" :visible="modals.validationContractExtension" backdrop="static">
    <div class="app-modal scope-upload__modal">
      <div class="app-modal__icon">
        <CIcon class="scope-upload__modal-icon" name="cil-face-dead" />
      </div>
      <h4 class="app-modal__title">File not supported!</h4>
      <p class="app-modal__text">
        Oops, this file format is not recognized yet by our platform. Currently only pdf format is supported.
      </p>
      <div class="app-modal__buttons">
        <CButton color="primary" @click="modals.validationContractExtension = false">Got it!</CButton>
      </div>
    </div>
  </CModal>

  <CModal class="overflow-modal" :visible="modals.validationRequestExtension" backdrop="static">
    <div class="app-modal scope-upload__modal">
      <div class="app-modal__icon">
        <CIcon class="scope-upload__modal-icon" name="cil-face-dead" />
      </div>
      <h4 class="app-modal__title">File not supported!</h4>
      <p class="app-modal__text">
        Oops, this file format is not recognized yet by our platform.
        Here is the list of the supported formats: doc, docx, xlsx, xls, pdf, jpg, jpeg, gif, emp, svg, txt, png
      </p>
      <div class="app-modal__buttons">
        <CButton color="primary" @click="modals.validationRequestExtension = false">Got it!</CButton>
      </div>
    </div>
  </CModal>

  <CModal class="overflow-modal" :visible="modals.validationSize" backdrop="static">
    <div class="app-modal scope-upload__modal">
      <div class="app-modal__icon">
        <CIcon class="scope-upload__modal-icon" name="cil-face-dead" />
      </div>
      <h4 class="app-modal__title">File not supported!</h4>
      <p class="app-modal__text">
        Oops, this file size is not supported yet by our platform. Maximum file size - 100 mb.
      </p>
      <div class="app-modal__buttons">
        <CButton color="primary" @click="modals.validationSize = false">Got it!</CButton>
      </div>
    </div>
  </CModal>

  <CModal class="overflow-modal" :visible="modals.error" backdrop="static">
    <div class="app-modal scope-upload__modal">
      <div class="app-modal__icon">
        <CIcon class="scope-upload__modal-icon" name="cil-face-dead" />
      </div>
      <h4 class="app-modal__title">Oops, something went wrong!</h4>
      <p class="app-modal__text">
        Please try again.
      </p>
      <div class="app-modal__buttons">
        <CButton color="primary" @click="modals.error = false">Got it!</CButton>
      </div>
    </div>
  </CModal>

  <CModal class="overflow-modal" :visible="modals.cancel" backdrop="static">
    <div class="app-modal scope-upload__modal">
      <div class="app-modal__icon">
        <CIcon class="scope-upload__modal-icon" name="cil-warning" />
      </div>
      <h4 class="app-modal__title">Cancel changes?</h4>
      <p class="app-modal__text">Are you sure you want to cancel changes?</p>
      <div class="app-modal__buttons">
        <CButton variant="outline" color="primary" @click="modals.cancel = false">Cancel</CButton>
        <CButton color="primary" @click="onCancelConfirm">Confirm</CButton>
      </div>
    </div>
  </CModal>

  <CModal class="overflow-modal" :visible="!!modals.congrats" backdrop="static">
    <div class="app-modal scope-upload__modal">
      <div class="app-modal__icon">
        <img class="scope-upload__modal-icon" src="@/assets/images/well-done.svg" alt="Done"  />
      </div>
      <h4 class="app-modal__title">Congratulations!</h4>
      <p class="scope-upload__modal-text">
        The primary contract has been fully executed. Be sure all additional documents are uploaded shortly.
        The flowdown process has now started.
      </p>
      <ul class="scope-upload__modal-list">
        <li v-for="document in modals.congrats" :key="document.id">
          {{ document.name }} ({{ translate(document.type) }})
          {{ checkDateValid(document.due_date) ? '- By ' + formatDate(document.due_date) : '' }}
        </li>
      </ul>
      <p class="scope-upload__modal-text">
        View all flowdown and recurrency settings -
        <router-link :to="{name: 'ProjectTasksList', params: {id: $route.params.id }}">Access flowdown</router-link>
      </p>
      <div class="app-modal__buttons">
        <CButton
          style="min-width: 200px"
          color="primary"
          @click="modals.congrats = false">
          Got it!
        </CButton>
      </div>
    </div>
  </CModal>

  <ConfirmDialog ref="confirmDialog" />

</template>

<script>
import ScopeLoadingTable from "@/components/Tables/ScopeLoadingTable.vue";
import moment from "moment/moment";
import ConfirmDialog from '@/components/Modals/ConfirmDialog'
import CSelectSearch from "@/components/CSelectSearch.vue";

const vocabulary = {
  "external_company": "Company",
  "project_name": "Project",
  "project_scenario": "Scenario",
  "scope_package": "Package",
  "external_signer": "Default Signer (Sub)",

  "Contracts": 'Requested signature',
  "Request documents": 'Requested document',
  "Acknowledge": 'Requested acknowledgement'
}

export default {
  name: "ScopeUpload",
  components: {CSelectSearch, ScopeLoadingTable, ConfirmDialog},
  inject: ['toast'],
  data() {
    return {
      loading: false,
      uploading: false,
      mountedComponent: false,

      scope: {},
      labels: {},
      primary_tasks: [],
      other_tasks: [],

      modals: {
        noTasks: false,
        validationContractExtension: false,
        validationRequestExtension: false,
        validationSize: false,
        cancel: false,
        error: false
      },

      savedPreviousData: {},
      itemsUpdated: 1,
      nameEditing: false,
      namesWasEdit: false
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format('MM/DD/YYYY')
    },
    handleData(data) {
      this.itemsUpdated++;

      this.namesWasEdit = false;
      this.savedPreviousData = JSON.stringify(data.data);
      this.scope = data.data.scope;
      this.labels = data.data.labels;
      this.primary_tasks = data.data.primary_tasks;
      this.other_tasks = data.data.other_tasks;
    },
    getData() {
      this.loading = true;
      this.$http.scope.getScopeUploadData(this.$route.params.scopeId)
        .then(res => {
          this.handleData(res);
          this.loading = false;
        })
        .catch(e => {
          if (e.response.data.company_status === 'deleted' && e.response.data.external_company_id) {
            this.$router.push(`/companies/${ e.response.data.external_company_id }/info`)
          }
        })
    },
    translate(key) {
      return vocabulary[key]
    },
    onFileLoaded(payload) {
      const { file, taskId, isPrimary } = payload;
      const filesType =  isPrimary ? 'primary_tasks' : 'other_tasks';
      const taskToLoadIndex = this[filesType].findIndex(task => task.id === taskId);

      if (taskToLoadIndex < 0) {
        console.log('TASK ID NOT FOUND!');
        return;
      }

      this[filesType][taskToLoadIndex]['local_file'] = file;
    },
    onFileDelete(payload) {
      const { taskId, isPrimary } = payload;
      const filesType =  isPrimary ? 'primary_tasks' : 'other_tasks';
      const taskToLoadIndex = this[filesType].findIndex(task => task.id === taskId);

      if (taskToLoadIndex < 0) {
        console.log('TASK ID NOT FOUND!');
        return;
      }

      this[filesType][taskToLoadIndex]['local_file'] = null;
    },
    onModalOpen(modalName) {
      if (modalName === 'validation: Contracts') this.modals.validationContractExtension = true;
      if (modalName === 'validation: Request documents') this.modals.validationRequestExtension = true;
      if (modalName === 'validation: size') this.modals.validationSize = true;
    },
    onSave() {
      const scopeId = this.$route.params.scopeId;
      const tasks = [...this.primary_tasks, ...this.other_tasks];
      const tasksToSave = tasks.filter(task => !task.executed && task.local_file);
      const formData = new FormData();

      if (!tasksToSave.length) {
        this.modals.noTasks = true;
        return;
      }

      tasksToSave.forEach((task, i) => {
        formData.append(`tasks[${ i }][id]`, task.id)
        formData.append(`tasks[${ i }][type]`, task.type)
        formData.append(`tasks[${ i }][document_name]`, task.document_name)
        formData.append(`tasks[${ i }][file]`, task.local_file)
      })

      this.uploading = true;
      this.$http.scope.saveScopeUploadData(scopeId, formData)
        .then(res => {
          this.handleData(res.data);

          if (res.data.data.flowdown_tasks && res.data.data.flowdown_tasks.length) {
            this.modals.congrats = res.data.data.flowdown_tasks;
            return;
          }

          this.toast('success', res.data.message, 'Document(s) Uploaded');
        })
        .catch(() => this.modals.error = true)
        .finally(() => this.uploading = false)
    },
    onCancel() {
      if (this.checkChanges()) this.modals.cancel = true
    },
    onCancelConfirm() {
      this.modals.cancel = false;
      const savedData = JSON.parse(this.savedPreviousData);

      this.primary_tasks = savedData.primary_tasks;
      this.other_tasks = savedData.other_tasks;

      this.itemsUpdated++;
    },
    onDocumentNameChange(payload) {
      const { name, taskId, isPrimary } = payload;
      const filesType =  isPrimary ? 'primary_tasks' : 'other_tasks';
      const taskToLoadIndex = this[filesType].findIndex(task => task.id === taskId);

      this[filesType][taskToLoadIndex]['document_name'] = name;
      this.namesWasEdit = true;
    },
    checkChanges() {
      const tasks = [...this.primary_tasks, ...this.other_tasks];
      const tasksToSave = tasks.filter(task => !task.executed && task.local_file);

      return tasksToSave.length || this.namesWasEdit
    },
    checkDateValid(date) {
      return moment(date).isValid();
    }
  },
  computed: {
    filesNotLoaded() {
      const tasks = [...this.primary_tasks, ...this.other_tasks];
      const tasksToSave = tasks.filter(task => !task.executed && task.local_file);

      return !tasksToSave.length
    },
    dataLoaded() {
      return this.loading === false
    },
    updatedSummaryString() {
      return `Last Updated by ${this.scope.modifiedBy} ${ moment(this.scope.updated_at).format('MM/DD/YYYY hh:mm A') }`
    },
    saveDisabled() {
      return this.nameEditing
    }
  },
  created() {
    this.getData();
  },
  mounted() {
    this.mountedComponent = true;
  },
  async beforeRouteLeave() {
    if (this.checkChanges()) {
      await this.$refs.confirmDialog
        .confirm({
          text: this.$t('messages.unsaved'),
          confirmText: 'Leave',
          cancelText: 'Cancel',
          reverse: true,
        })
        .then((res) => {
          if (res) return true
          else {
            return Promise.reject(false)
          }
        })
    } else {
      return true
    }
  }
}
</script>

<style lang="scss">
.scope-upload {
  background: white;
  border-radius: 6px;
  padding: 24px;
  transition: 0.3s;

  &__modal {
    width: 688px;

    &-icon {
      height: 80px !important;
      width: 80px !important;
      color: #BAC6CF;
    }

    &-text {
      font-size: 16px;
      color: #677A89;
    }

    &-list {
      font-size: 16px;
      color: #677A89;
      margin: 40px 0;
      padding: 0 20px;
    }
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;

    height: calc(100vh - 260px);

    & .spinner-border {
      color: #0068ad;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__scope {

    &-name {
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 8px;
      color: #1C262F;
      border-bottom: 1px solid #C3CDD5;
    }

    &-updated {
      margin-bottom: 0;
      font-weight: 400;
      font-size: 12px;
      color: #677A89;
    }

    &-summary {
      display: flex;
      align-items: center;
      column-gap: 32px;
      flex-wrap: wrap;

      background: #F2F5F8;
      border-radius: 8px;
      padding: 5px 16px;
      min-height: 40px;

      &-item {
        font-size: 14px;
        color: #677A89;
      }
    }
  }

  &__table {

    &_top {
      border-bottom: 1px solid #C3CDD5;
    }

    &-title {
      display: flex;
      align-items: center;
      gap: 4px;

      color: #1C262F;
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 7px;
    }

    &-info {
      color: #677A89;
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 24px;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    gap: 16px;

    border-top: 1px solid #C3CDD5;
    padding: 24px 0 0 0;

    &-arrow {
      height: 10px;
      transform: rotate(-90deg);
    }

    &-link {

    }

    &-counter {
      margin-left: auto;
      color: #677A89;
      font-size: 16px;
    }
  }
}
</style>
