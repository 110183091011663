<template>
  <SmartTable class="scope-loading-table" :columns="columns" :items="items" :infinity="true">

    <template #type="{ item }">
      <div class="scope-loading-table__col scope-loading-table__type">
        <div
          :class="[
            'scope-loading-table__status-label',
            `scope-loading-table__status-label_${ item.executed ? 'executed' : 'default' }`,
            `scope-loading-table__status-label_${ item.local_file ? 'executed' : 'default' }`
          ]">
          <CIcon name="cilCheckAlt" />
        </div>
        {{ item.type }}
        <img v-show="primary" src="@/assets/images/crown-emblem.svg" alt="crown" />
      </div>
    </template>

    <template #document_name="{ item }">
      <div class="scope-loading-table__col scope-loading-table__document">
        <input
          v-if="itemInEdit && itemInEdit.id === item.id"
          maxlength="245"
          class="scope-loading-table__document-input"
          v-model="itemInEdit.document_name"
          @input="sanitizeName"
          type="text" />
        <span
          v-else
          :title="item.document_name"
          class="text-overflow-ellipsis">
          {{ item.document_name }}
        </span>

        <CIcon
          v-if="!item.executed && !itemInEdit && !nameEdition"
          class="scope-loading-table__document-edit"
          name="cil-pen"
          @click="openNameEditInput(item)" />

        <button
          v-if="itemInEdit && itemInEdit.id === item.id"
          class="scope-loading-table__document-button"
          @click="closeNameEditInput(item)">
          <CIcon class="scope-loading-table__document-edit scope-loading-table__document-edit_cancel" name="cil-x" />
        </button>

        <button
          v-if="itemInEdit && itemInEdit.id === item.id"
          class="scope-loading-table__document-button"
          :aria-disabled="itemInEdit && !itemInEdit.document_name.trim()"
          :disabled="itemInEdit && !itemInEdit.document_name.trim()"
          @click="onDocumentNameChange">
          <CIcon class="scope-loading-table__document-edit scope-loading-table__document-edit_confirm" name="cil-check" />
        </button>

      </div>
    </template>

    <template #due_date="{ item }">
      {{ formatDueDate(item.due_date) }}
    </template>

    <template #upload="{ item }">
      <div class="scope-loading-table__col">
        <div
          class="scope-loading-table__area"
          :class="{
            'scope-loading-table__area_hovered': hoveredOnDrag === item.id && !item.executed,
            'scope-loading-table__area_loaded': item.local_file && !item.executed
          }"
          @mouseleave="hoveredOnDrag = null"
          @dragleave.prevent="hoveredOnDrag = null"
          @drop.prevent="onFileDrop($event, item)"
          @dragover.prevent="dragOver(item.id)">

          <div
            v-if="!item.uploaded_filename && !item.executed && !item.local_file"
            class="
            scope-loading-table__area-content
            scope-loading-table__area-content_default"
            :class="{
              'scope-loading-table__area-content_default-hover': hoveredOnDrag
            }"
          >
            Drag & Drop File Here or
            <span @click="openFileBrowser(item.id)" style="cursor:pointer;">Browse File</span>
          </div>

          <div
            v-if="item.local_file"
            class="
            scope-loading-table__area-content
            scope-loading-table__area-content_loaded"
            :title="item.local_file.name"
          >
            <CIcon name="cil-file" />
            <span class="text-overflow-ellipsis">{{ item.local_file.name }}</span>
            <CIcon name="cil-x" @click="onFileDelete(item.id)" />
          </div>

          <div
            v-if="item.uploaded_filename"
            class="
            scope-loading-table__area-content
            scope-loading-table__area-content_loaded"
            :title="item.uploaded_filename"
          >
            <CIcon name="cil-file" />
            <span class="text-overflow-ellipsis">{{ item.uploaded_filename }}</span>
          </div>

          <input
            :ref="`task_${ item.id }_file_input`"
            class="scope-loading-table__area-input"
            type="file"
            @change="onFileLoaded($event, item)" />
        </div>

      </div>
    </template>

    <template #clear_text>
      <div class="scope-loading-table__clear">
        <h5>No document is missing</h5>
        <p>All the needed documents have been provided</p>
        <img src="@/assets/images/empty-table.svg" alt="">
      </div>
    </template>

  </SmartTable>
</template>

<script>
import SmartTable from "@/components/SmartTable/index.vue";
import moment from "moment/moment";

export default {
  name: "ScopeLoadingTable",
  props: ['primary', 'nameEdition'],
  components: {SmartTable},
  data() {
    return {
      columns: [
        { key: 'type', label: 'Type', _style: '', sorter: false },
        { key: 'document_name', label: 'Document Name', _style: '', sorter: false },
        { key: 'due_date', label: 'Due Date', _style: '', sorter: false },
        { key: 'upload', label: 'Upload', _style: '', sorter: false }
      ],
      items: [],

      hoveredOnDrag: false,
      validExtensions: {
        'Request documents': ['doc', 'docx', 'xlsx', 'xls', 'pdf', 'jpg', 'jpeg', 'gif', 'emp', 'svg', 'txt', 'png'],
        'Contracts': ['pdf']
      },
      maxFileSize: 1e+8,

      itemInEdit: null
    }
  },
  methods: {
    formatDueDate(date) {
      const dateObj = moment(date);

      if (!dateObj.isValid()) return 'N/A'

      return dateObj.format('MM/DD/YYYY');
    },
    onFileLoaded(e, task) {
      this.saveFileInTask(e.target.files[0], task);
    },
    onFileDelete(id) {
      const payload = {
        taskId: id,
        isPrimary: this.primary
      }

      this.removeFileFromInputElement(id);
      this.$emit('fileDeleted', payload);
    },
    dragOver(id) {
      this.hoveredOnDrag = id
    },
    onFileDrop(e, task) {
      if (!task.executed) this.saveFileInTask(e.dataTransfer.files[0], task);
    },
    openFileBrowser(taskId) {
      const inputEl = this.$refs[`task_${ taskId}_file_input`];
      if (inputEl) inputEl.click();
    },
    saveFileInTask(file, task) {
      const isValidFile = this.validateFile(file, task);

      if (isValidFile) {
        const payload = {
          file: file,
          taskId: task.id,
          isPrimary: this.primary
        }

        this.$emit('fileLoaded', payload);
      }
    },
    validateFile(file, task) {
      if (!file) return false
      const taskType = task.type;
      const fileExtension = file.name.split('.').pop();
      const isValidExtension = this.validExtensions[taskType].includes(fileExtension);
      const isValidSize = file.size < this.maxFileSize;

      if (!isValidExtension) {
        this.removeFileFromInputElement(task.id);
        this.$emit('openModal', `validation: ${ taskType }`);
        return;
      }

      if (!isValidSize) {
        this.removeFileFromInputElement(task.id);
        this.$emit('openModal', 'validation: size');
        return;
      }

      return true
    },
    removeFileFromInputElement(taskId) {
      const fileInputElement = this.$refs[`task_${taskId}_file_input`];
      if (fileInputElement) fileInputElement.value = '';
    },
    openNameEditInput(item) {
      this.$emit('name-edit-started');
      this.itemInEdit = item;
    },
    closeNameEditInput() {
      this.$emit('name-edit-finished');
      this.itemInEdit = null;
    },
    onDocumentNameChange() {
      const payload = {
        taskId: this.itemInEdit.id,
        name: this.itemInEdit.document_name,
        isPrimary: this.primary
      }

      this.closeNameEditInput();
      this.$emit('nameChanged', payload);
    },
    sanitizeName() {
      this.itemInEdit.document_name = this.itemInEdit.document_name.replace(/[<>:"\/\\|?*]/g, "");
    }
  }
}
</script>

<style lang="scss">
.scope-loading-table {

  & .SmartTable-col {
    height: 96px;
  }

  &__type {
    min-width: 200px;
  }

  &__document {
    max-width: 400px;
    width: 400px;

    &-button {
      height: 25px;
      width: 30px;

      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(195, 205, 213, 0.32);
      border: 1px solid transparent;
      border-radius: 200px;
      padding: 0;

      &:hover {
        border: 1px solid #979fa6;
      }

      & svg {
        //color: white;
        height: 14px !important;
        width: 14px !important;
      }
    }

    &-edit {
      cursor: pointer;

      &_confirm {
        color: green;
      }
    }

    &-input {
      height: 48px;
      width: 100%;
      border: 1px solid #C3CDD5;
      font-size: 18px;
      padding-left: 5px;
      border-radius: 8px;

      &:focus {
        outline: 1px solid #00437A;
      }
    }
  }

  &__col {
    display: flex;
    align-items: center;
    gap: 8px;

    &-wrapper {
      transition: 0.3s;
      border: 4px solid transparent;
      border-radius: 8px;
    }
  }

  &__area {
    display: flex;
    align-items: center;

    position: relative;
    width: 401px;
    height: 56px;
    font-size: 16px;
    border: 4px solid transparent;

    &_hovered {
      border: 4px solid #2BB0E0BF;
      border-radius: 12px;
      transition: 0.3s;
    }

    &_loaded {
      border: 4px solid #0BBD9980;
      border-radius: 12px;
      transition: 0.3s;
    }

    &-content {
      display: flex;
      align-items: center;

      width: 100%;
      height: 100%;
      padding: 0 16px;
      border-radius: 8px;

      &_loaded {
        display: flex;
        gap: 8px;

        color: #1C262F;
        font-size: 16px;
        background: #0bbd9926;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%230BBD99FF' stroke-width='2' stroke-dasharray='6' stroke-dashoffset='14' stroke-linecap='butt'/%3e%3c/svg%3e");

        & svg {
          color: #677A89;
          flex-shrink: 0;
        }

        & svg:last-child {
          z-index: 1000;
          cursor: pointer;
          margin-left: auto;

          &:hover {
            color: black;
          }
        }
      }

      &_default {
        color: #677A89;
        font-size: 16px;
        background: #ABE7FA40;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%2300437AFF' stroke-width='2' stroke-dasharray='6' stroke-dashoffset='14' stroke-linecap='butt'/%3e%3c/svg%3e");

        & span {
          color: #0068AD;
          text-decoration: underline;
          margin-left: 5px;
        }
      }
    }



    &-input {
      position: absolute;
      width: 0;
      height: 0;
      opacity: 0;
      //bottom: 100%;
    }
  }

  &__status-label {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 13px;
    width: 13px;
    border-radius: 200px;

    & svg {
      color: white;
      height: 9px !important;
    }

    &_default {
      background: #BAC6CF;
    }

    &_executed {
      background: #0BBD99;
    }
  }

  &__clear {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 60px 15px;

    & h5 {
      font-size: 18px;
      font-weight: 600;
      color: #1C262F;
      margin-bottom: 4px;
    }

    & p {
      color: #677A89;
      font-size: 14px;
      margin-bottom: 24px;
    }
  }
}
</style>
